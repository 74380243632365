'use strict'
import Swiper, { Navigation, Pagination, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Slider =  {
    init: function(){
        this.initSliderImage();
        this.initSliderVideo();
        this.initSliderEvent();
    },

    smartCursorPosition: function(detector, cursor) {
        if(window.innerWidth > 991) {
            detector.addEventListener('mousemove', (e) => {
                const rect = detector.getBoundingClientRect();
                const cursorWidth = cursor.offsetWidth;
                const cursorHeight = cursor.offsetHeight;
                const cursorLeft = e.clientX - rect.left - cursorWidth / 2;
                const cursorTop = e.clientY - rect.top - cursorHeight / 2;

                cursor.style.left = cursorLeft + 'px';
                cursor.style.top = cursorTop + 'px';
                const keyframes = {
                    left: `${cursorLeft}px`,
                    top: `${cursorTop}px`,
                  };
                  cursor?.animate(keyframes, {
                    duration: 1000,
                    fill: 'forwards'
                  });
            });
        }
    },

    initSliderImage: function() {
        const sliders = document.querySelectorAll('.f-slider');
        if (sliders.length > 0) {
            sliders.forEach(slider => {
                const cursor = slider.querySelector('.smart-cursor');
                var swiper = new Swiper(slider, {
                    modules: [FreeMode],
                    slidesPerView: "auto",
                    freeMode: {
                        enabled: true,
                        sticky: false,
                        momentum: true,
                        minimumVelocity: 0.01,
                        momentumBounceRatio: 0.4,
                        momentumRatio: 1.4,
                        momentumVelocityRatio: 0.4
                    },
                    spaceBetween: 24,
                    breakpoints: {
                        991: {
                          spaceBetween: 32
                        }
                    }
                });
                if (cursor) {
                    this.smartCursorPosition(slider, cursor);
                }
            })
        }
    },
    initSliderEvent: function() {
        const sliders = document.querySelectorAll('.f-sliderEvent');
        if (sliders.length > 0) {
            sliders.forEach(slider => {
                const cursor = slider.querySelector('.smart-cursor');
                var swiper = new Swiper(slider, {
                    modules: [Navigation, FreeMode],
                    slidesPerView: 1,
                    freeMode: {
                        enabled: true,
                        sticky: false,
                        momentum: true,
                        minimumVelocity: 0.01,
                        momentumBounceRatio: 0.4,
                        momentumRatio: 1.4,
                        momentumVelocityRatio: 0.4
                    },
                    spaceBetween: 24,
                    breakpoints: {
                        991: {
                          spaceBetween: 32,
                          slidesPerView: 2
                        }
                    },
                    navigation: {
                        nextEl: ".f-slider_nav-next",
                        prevEl: ".f-slider_nav-prev",
                    }
                });
                if (cursor) {
                    this.smartCursorPosition(slider, cursor);
                }
            })
        }
    },

    initSliderVideo: function() {
        function updSwiperNumericPagination() {
            if (this.el.querySelector(".f-slider_nav-counter")) {
                this.el.querySelector(".f-slider_nav-counter").innerHTML = '<span class="count">' + (this.realIndex + 1) + '</span> / <span class="total">' + this.slides.length + "</span>";
            }
            this.el.querySelectorAll('.f-slider-video_content').forEach(el => {
                el.classList.remove('open')
            })
        }
        const slidersVideo = document.querySelectorAll('.f-slider-video');
        if (slidersVideo.length > 0) {
            slidersVideo.forEach(slider => {
                // init slider 
                var swiper = new Swiper(slider, {
                    modules: [Navigation, Pagination],
                    slidesPerView: 1,
                    spaceBetween: 24,
                    speed: 800,
                    effect: 'ease-out',
                    // autoHeight: true,
                    centeredSlides: true,
                    navigation: {
                        nextEl: ".f-slider_nav-next",
                        prevEl: ".f-slider_nav-prev",
                      },
                      slideClass: 'f-slider-video_item',
                      on: {
                        init: updSwiperNumericPagination,
                        slideChange: updSwiperNumericPagination
                      }
                });
                const slides = slider.querySelectorAll('.f-slider-video_item');
                if (slides.length > 0) {
                    slides.forEach(slide => {
                        const cursor = slide.querySelector('.smart-cursor');
                        if (cursor) {
                            this.smartCursorPosition(slider, cursor);
                        }
                        // Open and hide modal details 
                        const modalDetails = slide.querySelector('.f-slider-video_content');
                        const btnOpen = slide.querySelector('.js-details-open');
                        if (modalDetails && btnOpen) {
                            const btnClose = modalDetails.querySelector('.f-slider-video_content-close')
                            btnOpen.addEventListener('click', ()=> {
                                if (window.innerWidth < 991) {
                                    modalDetails.classList.toggle('open');
                                } else {
                                    modalDetails.classList.add('open');
                                }
                            })
                            if (btnClose) {
                                btnClose.addEventListener('click', (e)=> {
                                    e.stopPropagation();
                                    modalDetails.classList.remove('open');
                                })
                            }
                        }
                    })
                }
                this.playVideoInModal(slider)
            })
        }
    },
    playVideoInModal: function(slider) {
        const slides = slider.querySelectorAll('.f-slider-video_item');
        const modal = slider.querySelector('.f-slider-video__modal');
        if (slides.length > 0 && modal) {
            const video = modal.querySelector('video');
            function toggleModal() {
                modal.classList.toggle('open');
            }
            // Recupere le data video url de l'item clicked et ouvre la modal en changeant la src de la video
            slides.forEach(slide => {
                const videoPlay = slide.querySelector('.f-slider-video_asset');
                if (slide.hasAttribute('data-video-url')) {
                    videoPlay.addEventListener('click', ()=> {
                        let videoUrl = slide.getAttribute('data-video-url');
                        if (videoUrl && video) {
                            video.src = videoUrl;
                            toggleModal();
                        }
                    })
                }
            })
            // Ferme la modal et arrete la video en cours 
            const closeBtn = modal.querySelector('.f-slider-video__modal__close-btn');
            if (closeBtn) {
                closeBtn.addEventListener("click", ()=> {
                    video.pause();
                    video.currentTime = 0;
                    toggleModal();
                });
            }
        }
    }
}

export default Slider;