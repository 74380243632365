'use strict';
// import customSelect from 'custom-select';
import Redirection from '../components/redirection'
import RiseUpDecal from './riseupDecal'


const Press = {
    fullUrl : data_api['url_press_releases'],
    filter: new Array(),
    resultsAll: new Array,
    resultsfilters: new Array,
    resultsElt:null,
    nbResultsElt:null,
    formElt:null,
    filterElt: null,
    step: 6,
    currentStep:0,
    nameBtn: '',
    imgDefault: '',
    filterPressType:null,
    filterPressCategory:"",
    isFilterInitialized: false,
    currentSearch: '',

	init: function(){
        const pressElt = document.querySelector('.c-press');
        if(pressElt) {
            Press.nameBtn = pressElt.querySelector('.c-press__results').dataset.btnname;
            Press.imgDefault = pressElt.querySelector('.c-press__results').dataset.imgdefault;
            Press.resultsElt = pressElt.querySelector('.c-press__content');
            Press.nbResultsElt = pressElt.querySelector('.c-press__nbresults');
            Press.filterElt = pressElt.querySelector('.c-press__filter--list');
            Press.formElt = pressElt.querySelector('.c-press__form');
            Press.filterPressType = pressElt.getAttribute('data-pressType');
            if(Press.filterPressType) {
                Press.eventsLoad();
                Press.loadJson(); //chargement du json
            }
        }
    },
    eventsLoad:function() {
        Press.formElt.addEventListener('submit', (e) => {
            e.preventDefault();
            Press.resultsElt.innerHTML='';
            Press.resultsElt.classList.remove('show--');
            document.querySelector('.c-loader').classList.remove('hide--');
            Press.nbResultsElt.classList.remove('show--');
            Press.currentSearch = Press.formElt.querySelector('input:not([type="submit"])').value;
            Press.loadJson();
        })
    },

    events: function() {
        Press.filterElt.querySelectorAll('select').forEach((select) => {
            select.addEventListener('change', function(e) {
                Press.resultsElt.innerHTML='';
                Press.resultsElt.classList.remove('show--');
                document.querySelector('.c-loader').classList.remove('hide--');
                Press.nbResultsElt.classList.remove('show--');

                if (select.getAttribute('name') === "filteryear") {
                    Press.filterYear = select.value;
                    Press.resultsfilters = Press.resultsAll.filter(Press.applyFilter);
                    Press.showFilteredResults();
                } else {
                    if (select.getAttribute('name') === "filtercategory") {
                        Press.filterPressCategory = select.value !== "all" ? select.value : "";
                    }
                    Press.loadJson(); 
                }
            });
        });

        window.addEventListener('scroll', function(e) {
            if (document.querySelector('.c-loader:not(.hide--)')) {
                var positionLoader = document.querySelector('.c-loader').getBoundingClientRect();
                if (positionLoader.top <= window.innerHeight) {
                    Press.showResults(Press.resultsfilters);
                }
            }
        });

        document.querySelector('.c-loader').addEventListener('click', function(e) {
            e.preventDefault();
            Press.showResults(Press.resultsfilters);
        });
    },

    applyFilter: function(obj) {
        // Filtre par année uniquement
        if (Press.filterYear && Press.filterYear !== "all") {
            return obj.year === Press.filterYear;
        }
        return true;
    },

    showFilteredResults: function() {
        Press.currentStep = 0;
        window.scrollTo({ behavior: 'smooth', top: 0 });
        Press.nbResults(Press.resultsfilters.length);
        Press.showResults(Press.resultsfilters);
    },
    loadJson: function() {
        let request = new XMLHttpRequest();
        request.open("GET", `${Press.fullUrl}&title=${Press.currentSearch}&pressType=${Press.filterPressType}&pressCategory=${Press.filterPressCategory}`);
        request.onreadystatechange = function() {
            if (request.readyState === 4 && request.status === 200) {
                const data = JSON.parse(request.responseText);
    
                if (!Press.isFilterInitialized) {
                    Press.filter = data.filters || {};
                    Press.showFilter();
                    Press.isFilterInitialized = true;
                }
    
                Press.resultsAll = data.content;
                Press.resultsfilters = Press.resultsAll.filter(Press.applyFilter);
                Press.showFilteredResults();
            }
		};
		request.send();
    },
    showFilter: function() {
        Press.filterElt.innerHTML = '';
        
        if (Press.filter) {
            for (const key in Press.filter) {
                const filtre = Press.filter[key];
                if (filtre) {
                    let items = "";
                    let count = 0;
                    for (const cle in filtre) {
                        items += `<option value="${cle}">${filtre[cle]}</option>`;
                        count++;
                    }
                    if(count>1) {
                        Press.filterElt.insertAdjacentHTML('beforeend', `<div class="c-select js-elt" data-cursor-hover><select name="filter${key}">${items}</select></div>`);
                    }
                }
            }
            
            var timer=setTimeout(function() {
                Press.prettyShowFilter(Press.filterElt);
                Press.events();
                clearTimeout(timer);
            }, 200)
            
        }
    },
    

    showResults: function(resultsList) {
        let items = "";
        const endSearch = Math.min(Press.currentStep + Press.step, resultsList.length);
        
        for (let i = Press.currentStep; i < endSearch; i++) {
            items += `<a class="c-press__item js-elt" href="${resultsList[i].url}">
                <div class="c-press__item--img">
                    <img src="${resultsList[i].image.src || Press.imgDefault}" alt="${resultsList[i].image.title || resultsList[i].title}" loading="lazy" />
                </div>
                <div class="c-press__item--content">
                    <p class="c-press__date"><span>${resultsList[i].date}</span></p>
                    <div class="c-press__description">
                        <h2 class="h4">${resultsList[i].title}</h2>
                        <div class="btn-simple"><span>${Press.nameBtn}</span><i class="icon-arrow"></i></div>
                    </div>
                </div>
            </a>`;
        }

        Press.currentStep +=Press.step;
        Press.resultsElt.insertAdjacentHTML('beforeend', items);
        Press.resultsElt.classList.add('show--');

        if(endSearch >= resultsList.length) {
            document.querySelector('.c-loader').classList.add('hide--');
        }

        Redirection.init();
        RiseUpDecal.init();
    },
    nbResults:function(number) {
        Press.nbResultsElt.querySelector('span').innerHTML = number;
        Press.nbResultsElt.classList.add('show--');
    },
    prettyShowFilter:function(filterElt) {
        filterElt.parentNode.classList.add('show--');
        Press.filterElt.querySelectorAll('.js-elt').forEach(function(elt, index) {
            setTimeout(function() {
                elt.style.transform = 'translateY(0)';
                elt.style.opacity = '1';
            }, 100 * index * 1.5);
        });
    }
};

export default Press;
